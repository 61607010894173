import { createRouter, createWebHistory } from 'vue-router';
import Home from '../components/Home.vue';
import Blog from '../components/Blog.vue';
import Blogitem from '../components/Blogitem.vue';
import Example from '../components/Example.vue';
import Login from '../components/admin/Login.vue';
import Dashboard from '../components/admin/Dashboard.vue';
import Logout from '../components/admin/Logout.vue';
import '../assets/styles/tailwind.css';
import '../assets/styles/style.css';

const routes = [
  {
    path: '/about',
    name: 'HomePage',
    component: Home,
  },
  {
    path: '/',
    name: 'BlogPage',
    component: Blog,
  },
  {
    path: '/blogitem/:id',
    name: 'BlogItem',
    component: Blogitem,
  },
  {
    path: '/Example',
    name: 'ExamplePage',
    component: Example,
  },
  // Admin routes
  {
    path: '/cmsadmin',
    name: 'AdminLogin',
    component: Login,
  },
  {
    path: '/cmsadmin/dashboard',
    name: 'AdminDashboard',
    component: Dashboard,
  },
  {
    path: '/cmsadmin/logout',
    name: 'AdminLogout',
    component: Logout,
  },
  // ... other routes
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
