<template>
    <!-- Include the navigation component at the top -->
    <navigation></navigation>

    <nav class="header flex items-center justify-between flex-wrap m-4 p-12 rounded-3xl">
    </nav>

  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
  <router-link v-for="(post, index) in paginatedPosts" :key="post.id" :to="'/blogitem/' + post.id" class="text-decoration-none">
    <div v-if="index === 0" class="p-4 blogitem big-post">
      <!-- Style for the first item (big post) -->
      <img :src="post.imageUrl" alt="Blog Post Image" class="w-full h-auto loading-overlay rounded-3xl">
      <h2 class="text-xl font-bold mb-2 blogitemtitle"> {{ post.title.rendered }}</h2>
      <p class="text-gray-700" v-html="post.excerpt.rendered"></p>
    </div>
    <div v-else class="p-4 blogitem">
      <!-- Style for the rest of the items -->
      <img :src="post.imageUrl" alt="Blog Post Image" class="w-full h-auto loading-overlay rounded-3xl">
      <h2 class="text-xl font-bold mb-2 blogitemtitle">{{ post.title.rendered }}</h2>
      <p class="text-gray-700" v-html="post.excerpt.rendered"></p>
    </div>
  </router-link>
    </div>

    <!-- Pagination controls -->
    <div class="mt-4 pagination text-center">
      <button @click="changePage(-1)" :disabled="currentPage === 1">Vorige Pagina</button>
      <span class="mx-2">Pagina {{ currentPage }} van {{ totalPages }}</span>
      <button @click="changePage(1)" :disabled="currentPage === totalPages">Volgende Pagina</button>
    </div>
  </div>


  <!-- Foooter -->
<section class="bg-black">
  <div class="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
      <p class="mt-8 text-base leading-6 text-center text-gray-400">
          © 2021 - 2024 Tv-Digitaal.eu All rights reserved.
      </p>
  </div>
</section>
</template>

<script>
import axios from 'axios';
import Navigation from './NavigationTop.vue';

export default {
  name: 'BlogPage',
  data() {
    return {
      posts: [],
      itemsPerPage: 6,
      currentPage: 1,
    };
  },
  components: {
    // Register the navigation component
    Navigation,
  },
  computed: {
    paginatedPosts() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.posts.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.posts.length / this.itemsPerPage);
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get('https://tv-digitaal.eu/wp/wp-json/wp/v2/posts?_embed')
        .then(response => {
          this.posts = response.data;
          this.fetchImages(); // Fetch images after fetching posts
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },
    fetchImages() {
      // Fetch images based on post data
      this.posts.forEach(post => {
        const imageId = post.featured_media;
        axios.get(`https://crowdnet.be/beheer/wp-json/wp/v2/media/${imageId}`)
          .then(response => {
            // Assuming the structure is similar to what you provided
            const imageUrl = response.data.guid.rendered;
            // Add imageUrl to each post
            post.imageUrl = imageUrl;
          })
          .catch(error => {
            console.error('Error fetching image:', error);
          });
      });
    },
    changePage(offset) {
      this.currentPage += offset;
    },
  },
};
</script>